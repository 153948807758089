@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "nowaylight";
  src: url("./assets/fonts/Poppins-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "nowaylight";
  src: url("./assets/fonts/Poppins-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "nowaylight";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "nowaylight";
  src: url("./assets/fonts/Poppins-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@layer base {
  html {
    font-family: "Poppins", system-ui, sans-serif;
  }
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0px;
}

.PhoneInputInput:focus {
  outline: none;
}

.scroll ::-webkit-scrollbar {
  width: 3px;
  background: red;
  background: white;
  border-radius: 0px;
}

.form-row {
  @apply flex flex-col gap-x-5 gap-y-2 mb-3 lg:flex-row my-5;
}

.loader {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
  transform: rotate(45deg);
}
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: -50px;
  animation: animloader 4s ease infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  animation: animloader2 2s ease infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 0 50px rgba(255, 255, 255, 0), 50px 50px rgba(255, 255, 255, 0),
      50px 100px rgba(255, 255, 255, 0), 0px 100px rgba(255, 255, 255, 0);
  }
  12% {
    box-shadow: 0 50px white, 50px 50px rgba(255, 255, 255, 0),
      50px 100px rgba(255, 255, 255, 0), 0px 100px rgba(255, 255, 255, 0);
  }
  25% {
    box-shadow: 0 50px white, 50px 50px white, 50px 100px rgba(255, 255, 255, 0),
      0px 100px rgba(255, 255, 255, 0);
  }
  37% {
    box-shadow: 0 50px white, 50px 50px white, 50px 100px white,
      0px 100px rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 50px white, 50px 50px white, 50px 100px white, 0px 100px white;
  }
  62% {
    box-shadow: 0 50px rgba(255, 255, 255, 0), 50px 50px white, 50px 100px white,
      0px 100px white;
  }
  75% {
    box-shadow: 0 50px rgba(255, 255, 255, 0), 50px 50px rgba(255, 255, 255, 0),
      50px 100px white, 0px 100px white;
  }
  87% {
    box-shadow: 0 50px rgba(255, 255, 255, 0), 50px 50px rgba(255, 255, 255, 0),
      50px 100px rgba(255, 255, 255, 0), 0px 100px white;
  }
  100% {
    box-shadow: 0 50px rgba(255, 255, 255, 0), 50px 50px rgba(255, 255, 255, 0),
      50px 100px rgba(255, 255, 255, 0), 0px 100px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader2 {
  0% {
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25% {
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50% {
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100% {
    transform: translate(0, 0) rotateX(0) rotateY(360deg);
  }
}
